@import "~bootstrap/scss/bootstrap";

$light-color: #ffe6c6;
$dark-color: #281705;

body {
  //font-family: "Helvetica Neue", helvetica, arial, sans-serif;

  font-family: Roboto, sans-serif;
  color: $dark-color;
  background-color: $light-color;
  background-image: url(../images/bgmipj.jpg);
  background-position: left top;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
}
h1 {
  font-size: 36px;
  font-weight: 700;
}
h2 {
  word-wrap: break-word;
  font-weight: 700;
  letter-spacing: -.02em;
}

/************************** HEADER ********************/
.header {
  overflow-y: hidden;
  .header-cover {
    background-image: url(../images/header-cover.jpg);
    padding: 100px 0;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    position: relative;

    .header-info {
      width: 1040px;
      max-width: 86%;
      margin: 0 auto;
      min-height: 2px;
      text-align: center;

      .header-inner {
        background: $dark-color;
        padding: 30px;
        display: inline-block;

        .header-title a {
          color: $light-color;
          text-transform: uppercase;
          letter-spacing: 4px;
          font-family: 'Raleway', sans-serif;
          font-size: 1.75em;
          text-decoration: none;
          font-weight: 700;
        }

        .header-description {
          color: rgba( 255, 255, 255, 0.5 );
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
          line-height: 1;
          font-size: 1.4em;
          margin: 0;
          text-align: center;
          &:before {

            content: "";
            display: block;
            width: 100px;
            height: 2px;
            background: rgba(255,255,255,0.1);
            margin: 20px auto;
          }
        }
      }
    }
  }
}
#main-menu {
  position: relative;
  z-index: 999;
  .navbar-nav > li {
    & > a {
      color: $light-color;
      font-size: 1.10em;
      font-weight: 700;
    }

    &.active > a {
      background-color: $light-color;
      color: $dark-color;
    }
  }
  .navbar {
    margin: 0;
    padding: 0;
    .navbar-toggler {
      margin: 5px 0;
    }
  }
  .site-title {
    margin: 0;
    padding: 0;
    font-size: 28px;
    line-height: 32px;
    word-wrap: break-word;
    font-weight: 700;
    letter-spacing: -.02em;
    text-align: left;
    a {

      color: #663b0d;
      text-decoration: none;
    }
  }
  .navbar-brand {
    padding: 0;
  }
  .site-description {
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    position: relative;
    display: inline-block;
    text-align: left;
    color: #777777;
  }

  #site-navigation {
    background: $dark-color !important;
    &.shrink {

      min-height: 50px;
      position: fixed;
      width: 100%;
      z-index: 1001;
      top: 0;

      h1.site-title {
        font-size: 19px;
        line-height: 11px;
      }
      p.site-description {
        font-size: 12px;
        line-height: 12px;
      }
      .heading-menu .site-branding-text {
        padding-top: 5px;
      }
      .site-branding-logo img {
        width: auto;
        max-height: 60px;
      }
    }
    .site-heading {
      margin-top: 5px;
    }
  }

  @media (min-width: 768px) {
    .nav > li:hover > a:before {
      width: 100%;
      left: 0;
    }

    /************** TEST ************/
    .container-fluid {
      display: block;
    }
    .site-heading {
      display: block !important;
      float: left;
    }
    #navbarCollapse {
      display: block !important;
    }
  }

  .nav > li {
    display: block;
    position: relative;
  }
  @media (min-width: 768px) {
    .navbar-nav > li > a {
      padding-top: 20px;
      padding-bottom: 20px;
      transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
    }
    .shrink .navbar-nav > li > a {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .nav > li > a:before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: $light-color;
      -webkit-transition: all 1s;
      transition: all 1s;
    }
    .nav > li.active > a:before {
      background-color: $dark-color;
    }
  }
}
.full-head-img {
  background: $dark-color;
  padding-top: 60px;
  padding-bottom: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
  color: $light-color;
}
.main-container {
  padding: 20px 20px;

  figure {
    &.image {
      text-align: center;
      margin: auto;
      &.image_resized {
        img {
          width: 100%;
        }
      }
    }
  }
  .tile {
    box-shadow: 0px 3px 11px 1px #38210a;
    background: rgba(255,255,255,0.4);
    color: $dark-color !important;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    padding: 15px 10px;
    a {
      fill: #000000;
      background-color: $dark-color;
      color: $light-color;
      text-decoration: none;
      display: inline-block;
      line-height: 1;
      font-size: 15px;
      padding: 12px 24px;
      border-radius: 3px;
      text-align: center;
      transition: all .3s;
    }
  }

  .bloc {
    background: rgba(255,255,255,0.4);
    border-radius: 5px;

    .maxWidth {
      width: 100%;
    }
  }

  .form-send {
    img {
      width: 100%;
    }
    font-size: 2rem;
  }



  #carousel h2 {
    margin: 0;
  }
  #carousel p {
    margin: 0.6em 0 0.6em 0;
  }
  #carousel-position {
    margin-top: 10px;
  }
  #carousel-position a img {
    width: 50px;
    heigth: 50px;
    margin-right: 3px;
    border: 1px solid #CCCCCC;
    opacity: 0.3;
  }
  #carousel-position a.active img {
    opacity: 1;
  }
  #carousel .carousel-summary {
    float: right;
    width: 48%;
    margin-top: 0;
  }
  #carousel .carousel-description {
    float: right;
    width: 48%;
    clear: right;
  }
  #carousel .carousel-image {
    display: inline-block;
    position: relative;
    width: 50%;
    float: left;
    font-size: 12px;
  }
  #carousel-image-container:before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    height: 100%;

  }
  .carousel-image-container-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    text-align:center; /* Align center inline elements */
    font: 0/0 a;
  }

  .carousel-image-container-image:before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .carousel-image-container-image img {
    vertical-align: middle;
    display: inline-block;
  }
  #carousel .carousel-image div.test,
  #carousel .carousel-image table {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    vertical-align: center;
  }

  div.test:before {
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    height: 100%;

  }

  #carousel span.detail {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    #carousel {
      border:  1px solid black;
      border-radius: 10px;
      width: 1158px;
      height: 645px;
      position: relative;
      overflow: hidden;
    }
    #carousel-list {
      width: 5000px;
      position: absolute;
    }
    .carousel-detail {
      float: left;
      width: 1158px;
      padding: 10px;
      box-sizing: border-box;
    }
    #carousel a.carousel-arrow {
      display: block;
      background-image: url('../images/arrow.png');
      background-position: -30px 0;
      width: 30px;
      height: 46px;
      position: absolute;
      top: 50%;
      margin-top: -23px;
      z-index: 1000;
      transition: none;
    }
    #carousel a.carousel-arrow:hover {
      background-position: 0px 0;
    }
    #carousel a#carousel-next {
      right: 0;
      background-position: -60px 0;
    }
    #carousel a#carousel-next:hover {
      background-position: -90px 0;
    }
  }


  @media (min-width: 768px) {
    #carousel {
      width: 560px;
    }
    .carousel-detail {
      width: 560px;
    }
  }

  @media (min-width: 992px) {
    #carousel {
      width: 800px;
    }
    .carousel-detail {
      width: 800px;
    }
  }

  @media (min-width: 1200px) {
    #carousel {
      width: 980px;
    }
    .carousel-detail {
      width: 980px;
    }
  }

  @media (min-width: 1400px) {
    #carousel {
      width: 1158px;
    }
    .carousel-detail {
      width: 1158px;
    }
  }

  @media (max-width: 768px) {
    #carousel .carousel-detail img {

    }
  }
  @media (max-width: 768px) {
    .carousel-detail {
      padding: 2px 0 5px 0;
      margin-bottom: 5px;
      border-bottom: 1px solid black;
      position: relative;
    }
    .carousel-detail p.carousel-description {
      margin-left: 110px;
    }
    #carousel p.carousel-summary {
      float: none;
      width: auto;
    }
    .carousel-detail::after {
      height: 5px;
      width: 100%;
      background-color: red;
      position: absolute;
      bottom: -5px;
      left:0;
      content: "";background: #848484; /* Old browsers */
      background: -moz-linear-gradient(top,  #848484 0%, #ffffff 100%); /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#848484), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(top,  #848484 0%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(top,  #848484 0%,#ffffff 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(top,  #848484 0%,#ffffff 100%); /* IE10+ */
      background: linear-gradient(to bottom,  #848484 0%,#ffffff 100%); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#848484', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    #carousel-list {
      width: 100% !important;
    }
    #carousel .carousel-image {
      width: 43%;
    }
    #carousel .carousel-description {
      width: 53%;
    }
    #carousel-position {
      display: none;
    }
  }
}

footer.footer-credits {

    background-color: #1e1e1e;
    clear: both;
  .footer-credits-text {
    padding: 20px 0;
    color: $light-color;
    background-color: #1e1e1e;
  }
}
